export const dynamicScaleComponent = {
    props: {
        data:{type: Object},
        disabled: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        data: function() {
            this.fillScaleFromData();
        }
    },

    mounted() {
        this.fillScaleFromData();
    },

    methods: {
        fillScaleFromData(){
            for (const [key, value] of Object.entries(this.scale)) {
                this.scale[key] = this.data ? this.data[key] : null;
            }
        }
    }
}